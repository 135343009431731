<template>
	<div class=''>
		<!-- 首页大banner -->
		<div class="banner_swiper">
			<img :src="bannerImg" class="w-max" style="object-fit: cover;">
			<!-- <el-carousel height="1024px" indicator-position='none'>
        <el-carousel-item v-for="item in 4" :key="item">
         
        </el-carousel-item>
      </el-carousel> -->
		</div>
		<!-- //首页大banner -->
		<div class="nav_box">
			<sideList :navList='navList' width='270px' @navBtn='navBtn' url='patentReport'/>
		</div>
		<div class="content relative pr85">
			<div class="report_box ml575 pt70 pb120">
				<div class="fs32 fw-600 text-center">{{content}}</div>
				<!-- 报告列表 -->
				<div class="flex flex-wrap">
					<div class="w600 h600 pt40 mt40 mr60 report_item pl30 pr30" style="background: #F9F9F9;color: #32496C;"
						v-for="(item,i) in list" :key="i">
						<div class="fs18 text-center mb8">{{item.patentId}}</div>
						<div class="fs24 fw-600 text-center">{{item.patentName}}</div>
						<div class="fs18 text-center mt12 pointer" @click="$router.push(`/patentD/${item.id}`)">查看证书<i
								class="el-icon-arrow-right"></i></div>
						<div class="flex row-center mt14">
							<img :src="item.pcPic" class="w290 h370">
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import sideList from '@/components/sideList'
	import bannerImgJs from '@/mixin/bannerImg'
	import { getPatentCategory, getPatents } from '@/api'
	export default {
		name: 'patentReport',
		data() {
			return {
				navList: [],
				bannerType: 5,
				list: [],
				content:''
			};
		},
		mixins: [bannerImgJs],
		components: { sideList },

		created() {
			this.$store.commit('editRouterName', this.$route.name)
			//获取专利报告分类列表
			this.getPatentCategory()
		},

		mounted() {},

		methods: {
			//获取专利报告分类列表
			async getPatentCategory() {
				const res = await getPatentCategory({ pageNum: 1, pageSize: 1000 })
				this.navList = res.data
				this.content=res.data[0].content
				this.getPatents(res.data[0].id)
			},
			//获取专利报告分类列表
			async getPatents(id) {
				const res = await getPatents({ id })
				this.list = res.data
			},
			//子传父  切换分类
			navBtn(id) {
				const arr= this.navList.filter(item=>item.id==id)
				this.content=arr[0].content
				this.getPatents(id)
			},
		}
	}
</script>
<style lang='scss' scoped>
	.banner_swiper {
		/* height: 1024px; */
	}

	.nav_box {
		width: 270px;
		position: sticky;
		top: 90px;
		z-index: 999;
		/* margin-top: -30px; */
	}

.report_box {
	margin-top: -300px;
	/* min-height: 1550px; */
}
	.report_item:nth-child(even) {
		margin-right: 0px !important;
	}
</style>
