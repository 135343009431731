<template>
	<div class="nav pl30 pr30 pt30 pb30 ml60" :style="`width:${width} ;`">
		<div class="fs18 fw-600 flex row-between h60 col-center pointer" style="z-index: 999;" :class="{'color-f':active==i}"
			v-for="(item,i) in navList" :key="i" @click="check(item,i)">
			<span>{{item.categoryName}}</span>
			<i class="el-icon-arrow-right"></i>
		</div>
	</div>
</template>

<script>
	export default {
		name: "sideList",
		props: {
			navList: {},
			url: {},
			width: {}
		},
		data() {
			return {
				active: 0
			};
		},

		components: {},

		created() {},

		mounted() {},

		methods: {
			check(item, i) {
				this.$router.push(this.url)
				this.active = i
				this.$emit('navBtn', item.id)
			}
		}
	}
</script>
<style lang='scss' scoped>
	.nav {
/* 		position: sticky;
		top: 180px; */
		/* height: 400px; */
		background: #f7b500;
		z-index: 999;
	}
</style>
